<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DealAutoComplete from "@/components/deal-auto-complete-input.vue";
import {getDealApi} from '@/api/deal'
import {financial} from '@/api/misc'

/**
 * User list component
 */
export default {
  page: {
    title: "Collapsed Deal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Collapsed Deal",
      items: [
        {
          text: "Deal",
          href: "/",
        },
        {
          text: "Collapsed Deal",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      searchKey : '',
      query_filter : '',
      fields: [
      
        {
          key: "DealId",
          label : 'Deal #',
          sortable: false,
        },
         
        {
          key: "Type",
          sortable: false,
        },
     
        {
          key: "Agent",
          sortable: true,
        },
        {
          key: "Address",
          sortable: true,
        },
     
        {
          key: "CollapsedDate",
          sortable: true,
        },
        {
          key: "Price",
          sortable: true,
        },
        // {
        //   key: "Operator",
        //   label: 'OP',
        //   sortable: false,
        // },
       
      
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    DealAutoComplete
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  watch: {
    sortBy : function(){this.queryList()},
    sortDesc : function(){this.queryList()}
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
  
  },
  methods: {

    queryList() {

      let status_list = ['COLLAPSED']

      let data = {
        status: status_list,
         page : {
          page : this.currentPage,
           page_size: this.perPage
        },
        query_filter : this.query_filter
      }


      if (this.sortBy) {
        if (this.sortBy == 'CollapsedDate') {
          data.sort_by = 'collapsed_date'
        } else if (this.sortBy == 'Price') {
          data.sort_by = 'selling_price'
        }
        data.sort_desc = this.sortDesc?' DESC ' : ' ASC '
      }
     

     
      getDealApi().list(data).then((res)=>{
        this.listingData = []
        res.data.map((o) =>{
        
          this.listingData.push(o)
          this.totalRows = res.page.total;
         
        })  

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

   

    getPrice(price) {
      if (price == 'N/A')
        return price
      if (price >= 1000000) {
        return  financial(price /1000000.0, 3)+'M'
      } else {
        return  financial(price /1000.0, 3)+'K'
      }
    },

    onDealSelect(evt) {
      this.query_filter = evt.deal.order_deal_id
      this.currentPage  = 1
      this.queryList()
    },

    onFilterCleared() {
      this.query_filter = ''
      this.currentPage  = 1
      this.queryList()
    }


  },
  validations: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <h5 class="card-title">
                    Collapsed Deal List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              
              <div class="col-md-4">
                <DealAutoComplete  :init_data='{placeholder :"Search by Deal number or address or MLS..."}' :search_states="['COLLAPSED']"   @onSelected="onDealSelect" @onCleared="onFilterCleared"/>
              </div>
              <!-- end col -->
              <div class="col-md-4">
                <div  class="d-flex flex-wrap justify-content-md-end mt-2  gap-2 mb-3" >
                
                </div>
              </div>
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                class="datatables"
                  :items="listingData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  sort-icon-left
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"

                >

              
                  <template  #cell(DealId)="data">
                    <router-link
                      :to="{ name : 'deal-overview', query: {deal_id : `${data.item.order_deal_id}` }}"
                      class="text-body fw-medium"
                      >{{ data.item.order_deal_id}}</router-link
                    >
                  </template>
                 
                  <template #cell(Agent)="data"> 
                    <span v-for="(a, idx) in data.item.native_agents" :key="'agent_'+a.id+'_'+idx">
                      <router-link
                      :to="{name : 'brokerage-agent-view', params:{agent : a, agent_id : a.native_id+''}}"
                      class="text-body fw-medium"
                      >{{ `${a.first_name} ${a.last_name}`}}
                      </router-link><br />
                    </span>
                  </template>
                  <template #cell(Address)="data"> 
                   {{ data.item.address_desc }}
                  </template>

                  <template #cell(Price)="data">
                    ${{ getPrice(data.item.selling_price) }}
                  </template>

                
                  <template #cell(CollapsedDate)="data">
                    {{ data.item.collapsed_date }}
                  </template>
                
                  <template #cell(Type)="data">
                    {{ data.item.deal_business }}
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
